// Importing GSAP and ScrollTrigger from gsap
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Splitting from 'splitting';
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';

// Registering ScrollTrigger plugin with GSAP
gsap.registerPlugin(ScrollTrigger);

// Initializing Splitting
Splitting();

// Initializing variables
const spacing = 0.05,
	snap = gsap.utils.snap(spacing),
	cards = gsap.utils.toArray('.card'); // Converting all elements with class 'card' to an array

let seamlessLoop = buildSeamlessLoop(cards, spacing), // Building a seamless loop with the cards and spacing
	scrub = gsap.to(seamlessLoop, {
		// Creating a GSAP animation to the seamless loop
		totalTime: 0,
		duration: 0.5,
		ease: 'power3',
		paused: true,
	});

// Creating a ScrollTrigger
let scrollTrigger = createScrollTrigger();

let iteration = 10;

function createScrollTrigger() {
	return ScrollTrigger.create({
		start: 'cards',
		onUpdate(self) {
			if (self.progress === 1 && self.direction > 0 && !self.wrapping) {
				wrapForward(self);
			} else if (self.progress < 1e-5 && self.direction < 0 && !self.wrapping) {
				wrapBackward(self);
			} else {
				let newTotalTime = snap(
					(iteration + self.progress) * seamlessLoop.duration()
				);
				if (scrub.vars.totalTime !== newTotalTime) {
					scrub.vars.totalTime = newTotalTime;
					scrub.invalidate().restart();
				}
				self.wrapping = false;
			}
		},
		end: '+=3000',
		pin: '.cards',
	});
}

// Use requestAnimationFrame for your scroll events
function wrapForward(trigger) {
	iteration++;
	trigger.wrapping = true;
	trigger.scroll(trigger.start + 1);
}

function wrapBackward(trigger) {
	iteration--;
	trigger.wrapping = true;
	trigger.scroll(trigger.end - 1);
}
// Function to build a seamless loop
function buildSeamlessLoop(items, spacing) {
	// Initializing variables for the loop
	let overlap = Math.ceil((1 / spacing) * 2),
		startTime = items.length * spacing + 0.5,
		loopTime = (items.length + overlap) * spacing + 1,
		rawSequence = gsap.timeline({ paused: true }),
		seamlessLoop = gsap.timeline({
			paused: true,
			repeat: -1,
			onRepeat() {
				this._time === this._dur && (this._tTime += this._dur - 0.05);
			},
		}),
		l = items.length + overlap * 2,
		time = 0,
		i,
		index,
		item;

	// Setting initial properties for the items
	gsap.set(items, {
		yPercent: 400,
		opacity: 1,
		scale: 0,
		willChange: 'transform, opacity',
	});

	// Building the loop
	for (i = 0; i < l; i++) {
		index = i % items.length;
		item = items[index];
		time = i * spacing;
		rawSequence
			.fromTo(
				item,
				{ scale: 0, opacity: 1 },
				{
					scale: 1,
					opacity: 1,
					zIndex: 100,
					duration: 0.5,
					yoyo: true,
					repeat: 1,
					ease: 'power1.in',
					immediateRender: false,
				},
				time
			)
			.fromTo(
				item,
				{ yPercent: 300 },
				{
					yPercent: -200,
					duration: 1,
					ease: 'none',
					immediateRender: false,
				},
				time
			);
		i <= items.length && seamlessLoop.add('label' + i, time);
	}

	// Setting the time for the raw sequence and the seamless loop
	rawSequence.time(startTime);
	seamlessLoop
		.to(rawSequence, {
			time: loopTime,
			duration: loopTime - startTime,
			ease: 'none',
		})
		.fromTo(
			rawSequence,
			{ time: overlap * spacing + 1 },
			{
				time: startTime,
				duration: startTime - (overlap * spacing + 1),
				immediateRender: false,
				ease: 'none',
			}
		);

	// Add click event listener to each card
	items.forEach((card) => {
		// Check if the card has the 'newsletter' class
		if (!card.classList.contains('newsletter')) {
			card.addEventListener('click', () => {
				toggleCardExpansion(card);
			});
		}
	});

	return seamlessLoop;
}

function toggleCardExpansion(card) {
	// Stop the GSAP animation
	scrub.pause();

	if (card.classList.contains('expanded')) {
		// Revert to original size
		gsap.to(card, {
			scale: card.originalScale, // Use the original scale
			zIndex: card.originalZ, // Reset the z-index
			height: '$card-height',
			overflow: 'hidden',
			duration: 0.5,
			ease: 'power3',
		});

		card.classList.remove('expanded');

		// Enable the scroll event
		scrollTrigger.enable();

		// Scroll back to the original position
		window.scrollTo(0, card.originalScrollPosition);
	} else {
		// Store the original scale, scroll position and z-index
		card.originalScale = gsap.getProperty(card, 'scale');
		card.originalZ = gsap.getProperty(card, 'zIndex');
		card.originalScrollPosition = window.scrollY;

		// Expand the card
		gsap.to(card, {
			scale: 1, // Expand the card
			overflow: 'auto',
			zIndex: 1000, // Set a high z-index
			duration: 1,
			ease: 'power3',
		});

		card.classList.add('expanded');

		// Find all titles within the clicked card
		let titles = card.querySelectorAll('.text__flex__content[data-splitting]');

		// Loop over each title and apply the animation
		titles.forEach((title) => {
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: title,
					scroller: card, // Set the scroller to the clicked card
					start: 'top bottom',
					end: 'bottom center',
					scrub: true,
				},
			});

			tl.fromTo(
				title.querySelectorAll('.word'),
				{ opacity: 0.1 },
				{ ease: 'none', opacity: 1, stagger: 0.05 }
			);
		});

		// Disable the scroll event
		scrollTrigger.disable();
	}

	// Resume the GSAP animation
	scrub.resume();
}
